/* .ql-container {
    font-size: 14px;
} */

.ql-editor {
    line-height: 1.5;
    padding: 12px 15px;
    tab-size: 4;
    font-size: inherit;
    font-family: inherit;
}

.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3 {
    margin: 0;
    padding: 0;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor p,
.ql-editor ol,
.ql-editor ul {
    margin-bottom: 0.3em;  /* Reduced spacing between paragraphs */
}

.ql-editor ol,
.ql-editor ul {
    padding-left: 1.5em;
}

.ql-editor ol > li,
.ql-editor ul > li {
    list-style-type: none;
}

.ql-editor ul > li::before {
    content: '\2022';
}

.ql-editor ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0;
}

.ql-editor ol li:before {
    content: counter(list-0, decimal) '. ';
}

.ql-editor h1 {
    font-size: 2em;
}

.ql-editor h2 {
    font-size: 1.5em;
}

.ql-editor blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px;
}

.ql-editor code,
.ql-editor pre {
    background-color: #f0f0f0;
    border-radius: 3px;
}

.ql-editor pre {
    white-space: pre-wrap;
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 5px 10px;
}

.ql-editor code {
    font-size: 85%;
    padding: 2px 4px;
}

.ql-editor img {
    max-width: 100%;
    height: auto;
}